@import '../utils/base';

.create-server__label {
  font-weight: 700;
  cursor: pointer;

  @media (min-width: $mdMin) {
    text-align: right;
  }
}
